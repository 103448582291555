import axios from "axios";
import lodash from "lodash";
import VueApp from "vue";
import {formatDuration, intervalToDuration} from "date-fns";
import privilegeManager from "./privilege";
import store from "../store";

VueApp.prototype.setQueryTimeout = null;
VueApp.prototype.queryParams = {};

const UtilitiesCOMPUTED = {
  $getMonths() {
    return [
      {
        name: "January",
        short: "Jan",
        number: "1",
        days: 31
      },
      {
        name: "February",
        short: "Feb",
        number: "2",
        days: 28
      },
      {
        name: "March",
        short: "Mar",
        number: "3",
        days: 31
      },
      {
        name: "April",
        short: "Apr",
        number: "4",
        days: 30
      },
      {
        name: "May",
        short: "May",
        number: "5",
        days: 31
      },
      {
        name: "June",
        short: "Jun",
        number: "6",
        days: 30
      },
      {
        name: "July",
        short: "Jul",
        number: "7",
        days: 31
      },
      {
        name: "August",
        short: "Aug",
        number: "8",
        days: 31
      },
      {
        name: "September",
        short: "Sep",
        number: "9",
        days: 30
      },
      {
        name: "October",
        short: "Oct",
        number: "10",
        days: 31
      },
      {
        name: "November",
        short: "Nov",
        number: "11",
        days: 30
      },
      {
        name: "December",
        short: "Dec",
        number: "12",
        days: 31
      }
    ];
  },

  $orgId() {
    return store.state.linchpin.org.id;
  },
  $orgSettings() {
    return store.state.appSettings;
  },
  $OrgApps() {
    return store.state.linchpin.orgApps;
  },
  $AuthUser() {
    return store.state.linchpin.user;
  },
  $isRoot() {
    return store.state.linchpin.user.isRoot;
  },

  $OrgData() {
    return store.state.linchpin.org;
  },
  $getPopupContainer(trigger) {
    return trigger.parentElement;
  },
  $AXIOS() {
    return axios;
  }
};
const UtilitiesMETHODS = {
  $clearLocalStorage() {
    localStorage.removeItem("_tkn");
  },
  $popitup(url, windowName) {
    const newwindow = window.open(url, windowName, "height=200,width=150");
    if (window.focus) {
      newwindow.focus();
    }
    return false;
  },
  $exportFile(dataToExport, fileName) {
    // Create a Blob from the binary data received
    const blob = new Blob([dataToExport], { type: "application/octet-stream" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.csv`); // Set the file name

    // Simulate a click to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up: Remove the link and revoke the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  },
  $AXIOS_POST(url, payload) {
    return axios.post(this.$API_GATEWAY + url, payload, this.$simpleAuthHeader);
  },
  $AXIOS_PATCH(url, payload) {
    return axios.patch(
      this.$API_GATEWAY + url,
      payload,
      this.$simpleAuthHeader
    );
  },
  $AXIOS_GET(url) {
    return axios.get(this.$API_GATEWAY + url, this.$simpleAuthHeader);
  },
  $AXIOS_DELETE(url) {
    return axios.delete(this.$API_GATEWAY + url, this.$simpleAuthHeader);
  },
  $IsTrial() {
    const humanarApp = this.$OrgApps.find(app => app.appSlug === "humanar");
    if (humanarApp) {
      if (humanarApp.subscription.status === "trial") {
        return true;
      }

      return false;
    }
    return true;
  },

  async $CanAddEmployees() {
    if (this.$IsTrial()) {
      return true;
    }
    const { data } = await this.$_getUserCount();
    return new Promise((resolve, reject) => {
      if (data.numberOfUnallocatedUsers === 0) {
        reject();
      } else {
        resolve();
      }
    });
  },

  $handleLogout() {
    this.$_logout().then(() => {
      localStorage.clear();
      window.location.replace(`${process.env.VUE_APP_CLOUDENLY}sign-in/?domain=${this.$OrgData.alias}`);
    });
  },

  $removeActions() {
    localStorage.removeItem('action')
    localStorage.removeItem('request')
  },

  $SubscriptionExpiresIn3Days() {
    return (
      +new Date(store.state.expiryDate) <
      new Date().setDate(new Date().getDate() + 3)
    );
  },
  $SubscriptionExpiresIn7Days() {
    return (
      +new Date(store.state.expiryDate) <
      new Date().setDate(new Date().getDate() + 7)
    );
  },

  $handlePrivilege(subModuleName, privilege) {
    const { isRoot } = store.state.linchpin.user;
    if (isRoot === true) {
      // The root user has all privileges so there is need to check
      return true;
    }
    const { moduleName, privileges } = store.state;
    const result = privilegeManager(
      privileges,
      moduleName,
      subModuleName,
      privilege
    );
    return new Promise((resolve, reject) => {
      if (!result) {
        reject();
      } else {
        resolve();
      }
    });
  },
  $getOrdinal(i) {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
      return `${i}st`;
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  },
  $getRomanFigure(num) {
    const roman = {
      m: 1000,
      cm: 900,
      d: 500,
      cd: 400,
      c: 100,
      xc: 90,
      l: 50,
      xl: 40,
      x: 10,
      ix: 9,
      v: 5,
      iv: 4,
      i: 1
    };
    let str = "";

    // eslint-disable-next-line no-restricted-syntax
    for (const i of Object.keys(roman)) {
      const q = Math.floor(num / roman[i]);
      // eslint-disable-next-line no-param-reassign
      num -= q * roman[i];
      str += i.repeat(q);
    }

    return str;
  },
  $getInitials(name) {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
      initials =
        nameSplit[0].substring(0, 1) +
        nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
      initials = nameSplit[0].substring(0, 1);
    }
    return initials.toUpperCase();
  },
  $getLengthOfService(date) {
    return formatDuration(
      intervalToDuration({
        start: new Date(date),
        end: new Date()
      }),
      { format: ["years", "months", "days"] }
    ) || '-'
  },
  $_getOrgProfile() {
    return this.$AXIOS_GET(
      `org/${this.$store.state.orgId}`,
      this.$simpleAuthHeader
    );
  },
  $_getProvinces(countryAlpha3Code) {
    return axios.get(
      `${this.$API_GATEWAY}utilities/countries/${countryAlpha3Code}/province`,
      this.$store.state.$simpleAuthHeader
    );
  },
  $_getBanks() {
    return axios.get(
      `${this.$apiUtilities}localbanks`,
      this.$store.state.$simpleAuthHeader
    );
  },
  $_getLocalbanks() {
    return axios.get(
      `${this.$apiUtilities}localbanks`,
      this.$store.state.$simpleAuthHeader
    );
  },
  $_getCurrencies() {
    return axios.get(
      `${this.$API_GATEWAY}utilities/currencies`,
      this.$store.state.$simpleAuthHeader
    );
  },
  $_getCities(state) {
    return axios.get(
      `${this.$apiUtilities}cities/${state}`,
      this.$store.state.$simpleAuthHeader
    );
  },
  $_getLga(state, location) {
    return axios.get(
      `${this.$apiUtilities}lga/${state}/${location}`,
      this.$store.state.$simpleAuthHeader
    );
  },
  $_getPostCode(state, location) {
    return axios.get(
      `${this.$apiUtilities}postcode/${state}/${location}`,
      this.$store.state.$simpleAuthHeader
    );
  },
  $_getCountriesPhoneCodes() {
    return axios.get(
      `${this.$API_GATEWAY}utilities/countries-phone-codes`,
      this.$store.state.$simpleAuthHeader
    );
  },
  $_getPlanMatrix() {
    return axios.get(`${this.$API_GATEWAY}subscription/plan-matrix`);
  },
  $_getPayrollPfas() {
    return axios.get(
      `${this.$API_GATEWAY}payroll/utilities/pfas`,
      this.$store.state.$simpleAuthHeader
    );
  },
  $_getPayrollIrsBanks() {
    return axios.get(
      `${this.$API_GATEWAY}payroll/utilities/irs-banks`,
      this.$store.state.$simpleAuthHeader
    );
  },
  $_getORGPensionData() {
    return axios.get(
      `${this.$API_GATEWAY}payroll/${this.$userData.businessId}/settings/pension`,
      this.$simpleAuthHeader
    );
  },
  $_getORGTaxData() {
    return axios.get(
      `${this.$API_GATEWAY}payroll/${this.$userData.businessId}/settings/tax/all`,
      this.$simpleAuthHeader
    );
  },

  Capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  },
  randomString(length, chars) {
    let mask = "";
    if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
    if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (chars.indexOf("#") > -1) mask += "0123456789";
    if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
    let result = "";
    for (let i = length; i > 0; --i)
      result += mask[Math.floor(Math.random() * mask.length)];
    return result;
  },
  $lengthOfObject(theObject) {
    return Object.keys(theObject).length;
  },
  validateState(ref) {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || this.veeFields[ref].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  },
  validateMany(elements) {
    let state = true;
    elements.forEach(element => {
      if (!this.validateState(element)) {
        state = false;
      }
    });
    return state;
  },
  handlePayFrequency(value) {
    switch (value) {
      case "twice_monthly":
        return "Twice Monthly";
      case "monthly":
        return "Monthly";
      case "daily":
        return "Daily";
      case "weekly":
        return "Four Times Monthly";
      default:
        return "";
    }
  },
  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  },
  $convertCamelCaseToTitle(str) {
    let result = str.replace(/([A-Z])/g, ' $1').toLowerCase();
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
  },
  $formatToComma(num) {
    return num === undefined
      ? 0
      : num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },

  $getRateLabelBySlug(slug) {
    if (store.state.appDefaultApiResources.payrollRateTypes.length > 0) {
      const rateType = store.state.appDefaultApiResources.payrollRateTypes.find(
        _rateType => _rateType.slug === slug
      );
      if (!lodash.isEmpty(rateType)) {
        return rateType.label;
      }
      return null;
    }
    return null;
  },
  $queryBuilder(queryParams, callback) {
    this.queryParams = { ...this.queryParams, ...queryParams };
    clearTimeout(this.setQueryTimeout);
    this.setQueryTimeout = setTimeout(() => {
      if (queryParams === undefined) return callback({});
      return callback(this.queryParams);
    }, 1000);
  },
  $queryContainerLength(container) {
    let totalWidth = 0;
    if (container && container.length > 0) {
      for (let i = 0; i < container.length; i++) {
        totalWidth += container[i].offsetWidth;
      }
    }
    else {
      totalWidth = container.offsetWidth
    }
    return totalWidth
  }
};
// export { UtilitiesCOMPUTED, UtilitiesMETHODS };

const UtilityService = {
  install(Vue) {
    Vue.mixin({
      computed: UtilitiesCOMPUTED,
      methods: UtilitiesMETHODS
    });
  }
};

// Automatic installation if Vue has been added to the global scope.
if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(UtilityService);
}

export default UtilityService;
