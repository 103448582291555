/* eslint-disable import/prefer-default-export */
import { AxiosPerformance, OrgId } from './orgRepository';

export const PerformanceApi = {
  $_getEmployeesKPA(payload) {
    const url = `/v1/get/employee-kpas/${OrgId}/${payload}`;
    return AxiosPerformance.get(url, payload);
  },
  $_postEmployeesKPA(payload) {
    const url = '/v1/add/employee-kpa';
    return AxiosPerformance.post(url, payload);
  },
  $_editEmployeesKPA(payload) {
    const url = '/v1/edit/emloyee-kpa';
    return AxiosPerformance.patch(url, payload);
  },
  $_deleteEmployeesKPA(payload) {
    const url = `/v1/delete/employee-kpa/${payload.employeeId}/${payload.id}`;
    return AxiosPerformance.delete(url);
  },
  $_setLongTermKPAWeight(payload) {
    const url = `/v1/long-term/kpa-type/add-weight`;
    return AxiosPerformance.patch(url, payload);
  },
  $_getKPAtypes() {
    const url = `/v1/kpa-type/${OrgId}`;
    return AxiosPerformance.get(url);
  },
  $_getKPAWeights(id) {
    const url = `/v1/performance-template/get-kpa-types/${id}`;
    return AxiosPerformance.get(url);
  },
  $_addKPAWeights(payload) {
    const url = `/v1/kpa-type/add-weight`;
    return AxiosPerformance.patch(url, payload);
  },
  $_createKPAtypes(payload) {
    const url = '/v1/add/kpa-type';
    return AxiosPerformance.post(url, payload);
  },
  $_editKPAtypes(payload) {
    const url = '/v1/edit/kpa-type';
    return AxiosPerformance.patch(url, payload);
  },
  $_deleteKPAtypes(payload) {
    const url = `/v1/delete/kpa-type/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_getKPAobjective(payload) {
    const url = `/v1/kpa-objective/${OrgId}`;
    return AxiosPerformance.get(url, payload);
  },
  $_createKPAobjective(payload) {
    const url = '/v1/kpa-objective/create';
    return AxiosPerformance.post(url, payload);
  },
  $_editKPAobjective(payload) {
    const url = '/v1/kpa-objective/edit';
    return AxiosPerformance.post(url, payload);
  },
  $_deleteKPAobjective(payload) {
    const url = `/v1/delete/kpa-objective/${OrgId}/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_getPerfomanceRating() {
    const url = `/v1/performance-rating/${OrgId}`;
    return AxiosPerformance.get(url);
  },
  $_createPerfomanceRating(payload) {
    const url = '/v1/performance-rating/create';
    return AxiosPerformance.post(url, payload);
  },
  $_editPerfomanceRating(payload) {
    const url = '/v1-rating/edit';
    return AxiosPerformance.post(url, payload);
  },
  $_deletePerfomanceRating(payload) {
    const url = `/v1-rating/delete/${OrgId}/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_getPerfomanceTemplate(params) {
    const url = `/v1/performance-template/${OrgId}${params}`;
    return AxiosPerformance.get(url);
  },
  $_getOneTemplate(id) {
    const url = `/v1/performance-template/find-template/${id}`;
    return AxiosPerformance.get(url);
  },
  $_createPerfomanceTemplate(payload) {
    const url = '/v1/performance-template/create';
    return AxiosPerformance.post(url, payload);
  },
  $_createTemplateKPA(payload) {
    const url = `/v1/performance-template/create-with-kpas`;
    return AxiosPerformance.post(url, payload);
  },
  $_editPerfomanceTemplate(payload) {
    const url = '/v1-template/edit';
    return AxiosPerformance.post(url, payload);
  },
  $_deletePerfomanceTemplate(payload) {
    const url = `/v1/performance-template/delete/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_getAppraisalFlag() {
    const url = `/v1/get-flags/${OrgId}`;
    return AxiosPerformance.get(url);
  },
  $_createAppraisalFlag(payload) {
    const url = '/v1/add-new-flag';
    return AxiosPerformance.post(url, payload);
  },
  $_editAppraisalFlag(payload) {
    const url = '/v1/edit-flag';
    return AxiosPerformance.post(url, payload);
  },

  $_getPerformanceDescription() {
    const url = `/v1/performance/description/${OrgId}`;
    return AxiosPerformance.get(url);
  },
  $_createPerfomanceDescription(payload) {
    const url = `/v1/add/performance/description`;
    return AxiosPerformance.post(url, payload);
  },

  $_getRanking(payload) {
    const url = `/v1/get-rankings/${OrgId}`;
    return AxiosPerformance.get(url, payload);
  },
  $_createRanking(payload) {
    const url = '/v1/add-new-rank';
    return AxiosPerformance.post(url, payload);
  },

  $_deleteRanking(payload) {
    const url = `/v1/delete-rank/${OrgId}/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_createAppraisalType(payload) {
    const url = `/v1/appraisal-type/create`;
    return AxiosPerformance.post(url, payload);
  },

  $_getAppraisalType() {
    const url = `/v1/appraisal-type/${OrgId}`;
    return AxiosPerformance.get(url);
  },

  $_orgScoreCard(params) {
    const url = `/v1/performance-appraisal/organization-scorecard/${OrgId}${params}`;
    return AxiosPerformance.get(url);
  },

  $_getAppraisalCycle() {
    const url = `/v1/appraisal-cycle/${OrgId}`;
    return AxiosPerformance.get(url);
  },

  $_deleteAppraisalCycle(id) {
    const url = `/v1/appraisal-cycle/delete/${id}`;
    return AxiosPerformance.delete(url);
  },

  $_createAppraisalCycle(payload) {
    const url = '/v1/appraisal-cycle/create';
    return AxiosPerformance.post(url, payload);
  },
  $_editAppraisalCycle(payload) {
    const url = '/v1/cycle/edit';
    return AxiosPerformance.post(url, payload);
  },

  $_getPerfomanceAgreement(payload) {
    const url = `/v1/employee-performance-agreement/${OrgId}`;
    return AxiosPerformance.get(url, payload);
  },
  $_createPerfomanceAgreement(payload) {
    const url = '/v1/employee-performance-agreement/create';
    return AxiosPerformance.post(url, payload);
  },
  $_editPerfomanceAgreement(payload) {
    const url = '/v1/employee-performance-agreement/edit';
    return AxiosPerformance.post(url, payload);
  },
  $_deletePerfomanceAgreement(payload) {
    const url = `/v1/employee-performance-agreement/delete/${OrgId}/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_getAgreementKPA(payload) {
    const url = `/v1/get-agreement-kpas/${OrgId}/${payload}`;
    return AxiosPerformance.get(url, payload);
  },
  $_postAgreementKPA(payload) {
    const url = '/v1/add-new-agreement-kpas';
    return AxiosPerformance.post(url, payload);
  },
  $_editAgreementKPA(payload) {
    const url = '/v1/edit/agreement-kpas';
    return AxiosPerformance.patch(url, payload);
  },
  $_deleteAgreementKPA(payload) {
    const url = `/v1/delete/agreement-kpas/${OrgId}/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_postApplicableTo(payload) {
    const url = `/v1/delete/agreement-kpas/${OrgId}/${payload}`;
    return AxiosPerformance.post(url, payload);
  },

  $_getOrganisationGoal(payload) {
    const url = `/v1/organization-goal/${OrgId}${payload}`;
    return AxiosPerformance.get(url);
  },
  $_postOrganisationGoal(payload) {
    const url = '/v1/add/organization-goal';
    return AxiosPerformance.post(url, payload);
  },
  $_editOrganisationGoal(payload) {
    const url = '/v1/edit/organization-goal';
    return AxiosPerformance.patch(url, payload);
  },
  $_deleteOrganisationGoal(payload) {
    const url = `/v1/delete/organization-goal/${OrgId}/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_getOrganisationPerformanceAppraisal(payload) {
    const url = `/v1/get/organization/appraisal/${OrgId}`;
    return AxiosPerformance.get(url, payload);
  },
  $_postOrganisationPerformanceAppraisal(payload) {
    const url = '/v1/add/organization/appraisal';
    return AxiosPerformance.post(url, payload);
  },

  $_getCommentAppraisal(payload) {
    const url = `/v1/get/comments/${OrgId}/${payload}`;
    return AxiosPerformance.get(url, payload);
  },
  $_postCommentAppraisal(payload) {
    const url = '/v1/add/new-comment';
    return AxiosPerformance.post(url, payload);
  },
  $_editCommentAppraisal(payload) {
    const url = '/v1/update/comment';
    return AxiosPerformance.patch(url, payload);
  },
  $_deleteCommentAppraisal(payload) {
    const url = `/v1/delete/comment/${payload.id}/${payload.employeeId}/${payload.performanceAppraisalId}/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_getShareAppraisal(payload) {
    const url = `/v1/get/shared/appraisal/${payload.performanceAppraisalId}/${payload.sharedBy}/`;
    return AxiosPerformance.get(url, payload);
  },
  $_postShareAppraisal(payload) {
    const url = '/v1/share/appraisal';
    return AxiosPerformance.post(url, payload);
  },
  $_editShareAppraisal(payload) {
    const url = '/v1/edit/shared/appraisal';
    return AxiosPerformance.patch(url, payload);
  },
  $_deleteShareAppraisal(payload) {
    const url = `/v1/delete/shared/appraisal/${payload.id}/${payload.employeeId}/${payload.performanceAppraisalId}/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_getIPF_ReviewType(payload) {
    const url = `/v1/ipf-review-type/${OrgId}`;
    return AxiosPerformance.get(url, payload);
  },
  $_postIPF_ReviewType(payload) {
    const url = '/v1/add/ipf-review-type';
    return AxiosPerformance.post(url, payload);
  },

  $_getIPF_ReviewCycle(payload) {
    const url = `/v1/ipf-review-circle/${OrgId}`;
    return AxiosPerformance.get(url, payload);
  },
  $_postIPF_ReviewCycle(payload) {
    const url = '/v1/add/ipf-review-circle';
    return AxiosPerformance.post(url, payload);
  },
  $_editIPF_ReviewCycle(payload) {
    const url = '/v1/edit/ipf-review-circle';
    return AxiosPerformance.patch(url, payload);
  },
  $_deleteIPF_ReviewCycle(payload) {
    const url = `/v1/delete/ipf-review-circle/${OrgId}/${payload}`;
    return AxiosPerformance.delete(url);
  },

  $_getKPI_Employee(payload) {
    const url = `/v1/kpi-employee/create`;
    return AxiosPerformance.get(url, payload);
  },
  $_postKPI_Employee(payload) {
    const url = '/v1/kpi-employee/edit';
    return AxiosPerformance.post(url, payload);
  },
  $_editKPI_Employee(payload) {
    const url = `/v1/kpi-employee/${payload}`;
    return AxiosPerformance.patch(url, payload);
  },
  $_deleteKPI_Employees(payload) {
    const url = `/v1/kpi-employee/delete/${payload.employeeId}/${payload.id}`;
    return AxiosPerformance.delete(url);
  },

  $_createEmployeePerformanceAppraisal(payload) {
    const url = '/v1-appraisal/employee';
    return AxiosPerformance.post(url, payload);
  },
  $_postEmployeePerformanceScore(payload) {
    const url = '/v1-score/create';
    return AxiosPerformance.post(url, payload);
  },
  $_editEmployeePerformanceScore(payload) {
    const url = '/v1-score/edit';
    return AxiosPerformance.patch(url, payload);
  },
  $_submitEmployeePerformanceAppraisal(payload) {
    const url = '/v1-appraisal/submit';
    return AxiosPerformance.post(url, payload);
  },
  $_revertSubmitEmployeePerformanceAppraisal(payload) {
    const url = '/v1-appraisal/revert';
    return AxiosPerformance.post(url, payload);
  },

  $_createModerationStructure(payload) {
    const url = '/v1/moderation/moderation_structure_moderator/create';
    return AxiosPerformance.post(url, payload);
  },

  $_getModerationStructure(params) {
    const url = `/v1/moderation/moderation_structure_moderator/${OrgId}${params}`;
    return AxiosPerformance.get(url);
  },

  $_deleteModerationStructure(id) {
    const url = `/v1/moderation/moderation_structure_moderator/delete/${id}`;
    return AxiosPerformance.delete(url);
  },

  $_getOneModerationStructure(id) {
    const url = `/v1/moderation/moderation_structure_moderator/find-moderation/${id}`;
    return AxiosPerformance.get(url);
  },

  $_editModerationStructure(payload) {
    const url = `/v1/moderation/moderation_structure_moderator/edit`;
    return AxiosPerformance.patch(url, payload);
  },

  $_getStartModerators(userId, year) {
    const url = `/v1/moderation/get-appraisee/${userId}?year=${year}`;
    return AxiosPerformance.get(url);
  },

  $_getOrgPerformanceModeration() {
    const url = `/v1/moderation/employees-moderation/${OrgId}`;
    return AxiosPerformance.get(url);
  },
  $_getOrgCeoPerformanceModeration() {
    const url = `/v1/get/ceo-moderations/${OrgId}`;
    return AxiosPerformance.get(url);
  },
  $_getFunctionalModeration(params) {
    const url = `/v1/moderation/employees-moderation/functional-moderation/${OrgId}?year=${params}`;
    return AxiosPerformance.get(url);
  },
  $_getCEOModeration(params) {
    const url = `/v1/moderation/employees-moderation/ceo-moderation/${OrgId}?year=${params}`;
    return AxiosPerformance.get(url);
  },
  $_getEmployeeModeration(userId) {
    const url = `/v1/moderation/employee-moderation/get-score/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_singleModeration(moderationId) {
    const url = `/v1/moderation/find-moderation/${moderationId}`;
    return AxiosPerformance.get(url);
  },

  $_postAddModerationScore(payload) {
    const url = `/v1/employee-moderation/add-moderation-score`;
    return AxiosPerformance.post(url, payload);
  },
  $_postSubmitNextLevelModeration(moderationId, payload) {
    const url = `/v1/moderation/employee-moderation/submit/${moderationId}`;
    return AxiosPerformance.patch(url, payload);
  },
  $_postBulkCeoModeration(payload) {
    const url = `/v1/add/ceo-moderation`;
    return AxiosPerformance.post(url, payload);
  },

  $_getPIPOutcome() {
    const url = `/v1/outcome-action/${OrgId}`;
    return AxiosPerformance.get(url);
  },

  $_createPIPOutcome(payload) {
    const url = `/v1/add/outcome-action`;
    return AxiosPerformance.post(url, payload);
  },

  $_editPIPOutcome(payload) {
    const url = `v1/update/outcome-action`;
    return AxiosPerformance.patch(url, payload);
  },

  $_deletePIPOutcome(id) {
    const url = `/v1/delete/outcome-action/${id}`;
    return AxiosPerformance.delete(url);
  },

  $_createPIPSetting(payload) {
    const url = `/v1/add-new-pip-plan`;
    return AxiosPerformance.post(url, payload);
  },

  $_getActivePIP(templateId) {
    const url = `/v1/pip-setting/find-active/${templateId}`;
    return AxiosPerformance.get(url);
  },

  $_getPIPSetting() {
    const url = `/v1/get-pip-plans/${OrgId}`;
    return AxiosPerformance.get(url);
  },

  $_deletePIPSetting(pipId) {
    const url = `/v1/delete-pip-plan/${pipId}`;
    return AxiosPerformance.delete(url);
  },

  $_createPerfomanceSetting(payload) {
    const url = `/v1/performance/settings`;
    return AxiosPerformance.post(url, payload);
  },

  $_getPerformanceGeneralSetting() {
    const url = `/v1/performance/settings/${OrgId}`;
    return AxiosPerformance.get(url);
  },

  $_updateAppraisalFlag(payload) {
    const url = `/v1/edit-flag`;
    return AxiosPerformance.patch(url, payload);
  },

  $_deleteAppraisalFlag(id) {
    const url = `/v1/delete-flag/${id}`;
    return AxiosPerformance.delete(url);
  },

  $_updatePerformanceDescription(payload) {
    const url = `/v1/update/performance/description`;
    return AxiosPerformance.patch(url, payload);
  },

  $_deletePerformanceDescription(id) {
    const url = `/v1/delete/performance/description/${id}`;
    return AxiosPerformance.delete(url);
  },

  $_getOrganizationGoal(params) {
    const url = `/v1/organization-goal/${OrgId}${params}`;
    return AxiosPerformance.get(url);
  },

  $_createShortTermGoal(payload) {
    const url = `/v1/organization-goal/create`;
    return AxiosPerformance.post(url, payload);
  },

  $_deleteShortTermGoal(id) {
    const url = `/v1/organization-goal/delete/${id}`;
    return AxiosPerformance.delete(url);
  },

  $_createLongTermKPA(payload) {
    const url = `/v1/long-term/kpa-type/create`;
    return AxiosPerformance.post(url, payload);
  },

  $_editLongTermKPA(payload) {
    const url = `/v1/long-term/kpa-type/update`;
    return AxiosPerformance.patch(url, payload);
  },

  $_getLongTermKPA() {
    const url = `/v1/long-term/kpa-type/${OrgId}`;
    return AxiosPerformance.get(url);
  },

  $_deleteKPAListtypes(Id) {
    const url = `/v1/long-term/kpa-type/delete/${Id}`;
    return AxiosPerformance.delete(url);
  },

  $_createLongTermGoal(payload) {
    const url = `/v1/organization-goal/long-term/create`;
    return AxiosPerformance.post(url, payload);
  },

  $_getLongTermGoal(params) {
    const url = `/v1/organization-goal/long-term/${OrgId}${params}`;
    return AxiosPerformance.get(url);
  },

  $_lockKPA(payload) {
    const url = `/v1/edit/emloyee-kpa`;
    return AxiosPerformance.patch(url, payload);
  },

  $_getOneShortTermGoal(id) {
    const url = `/v1/organization-goal/find-goal/${id}`;
    return AxiosPerformance.get(url);
  },

  $_getOneLongTermGoal(id) {
    const url = `/v1/organization-goal/long-term/find-goal/${id}`;
    return AxiosPerformance.get(url);
  },

  $_editShortTermGoal(payload) {
    const url = `/v1/organization-goal/update`;
    return AxiosPerformance.patch(url, payload);
  },

  $_editLongTermGoal(payload) {
    const url = `/v1/organization-goal/long-term/update`;
    return AxiosPerformance.patch(url, payload);
  },

  $_deleteLongTermGoal(id) {
    const url = `/v1/organization-goal/long-term/delete/${id}`;
    return AxiosPerformance.delete(url);
  },

  $_createEmployeeAgreement(payload) {
    const url = `/v1/employee/performance-agreement/create`;
    return AxiosPerformance.post(url, payload);
  },

  $_getEmployeeAgreement(userId) {
    const url = `/v1/employee/performance-agreement/employee/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_getApprovedEmployeeAgreement(userId) {
    const url = `/v1/employee/performance-agreement/employee/${userId}?approved=true&submitted=true`;
    return AxiosPerformance.get(url);
  },

  $_getManagerDirectReport(userId) {
    const url = `/v1/find-direct-reports/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_extendContractWindow(payload) {
    const url = `/v1/documentation/extension`;
    return AxiosPerformance.post(url, payload);
  },

  $_updateContractWindow(payload) {
    const url = `/v1/documentation/extension/update-document`;
    return AxiosPerformance.patch(url, payload);
  },

  $_getExtensionDocumentation(params, year) {
    const url = `/v1/documentation/extension/${OrgId}?operation=${params}&year=${year}`;
    return AxiosPerformance.get(url);
  },

  $_deleteExtensionDocumentation(id) {
    const url = `/v1/documentation/extension/delete/${id}`;
    return AxiosPerformance.delete(url);
  },

  $_checkEmployeeSharedAgreement(userId) {
    const url = `/v1/employee/performance-agreement/find-shared-agreement/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_createManagerAgreement(payload) {
    const url = `/v1/manager/performance-agreement/create`;
    return AxiosPerformance.post(url, payload);
  },

  $_getEmployeeSharedAgreement(id) {
    const url = `/v1/employee/shared/performance-agreement/${id}`;
    return AxiosPerformance.get(url);
  },

  $_getOneAgreement(id) {
    const url = `/v1/employee/performance-agreement/find-agreement/${id}`;
    return AxiosPerformance.get(url);
  },

  $_deleteAgreement(id) {
    const url = `/v1/employee/performance-agreement/delete/${id}`;
    return AxiosPerformance.delete(url);
  },

  $_getDirectReportAgreements(userId) {
    const url = `/v1/performance-agreement/manager/agreement-reports/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_getDirectReportsAgreementsList(userId,year,params) {
    const url = `/v1/performance-agreement/direct-reports-agreement/${OrgId}/${userId}?year=${year}${params}`;
    return AxiosPerformance.get(url);
  },

  $_viewEmployeeAgreements(userId) {
    const url = `/v1/employee/performance-agreement/view-agreement/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_deleteSharedAgreement(sharedId) {
    const url = `/v1/shared-agreement/unshare-agreement/${sharedId}`;
    return AxiosPerformance.delete(url);
  },

  $_allowEmployeeAction(payload) {
    const url = `/v1/manager/shared-agreement/allow-employee-decision`;
    return AxiosPerformance.post(url, payload);
  },

  $_overideEmployeeAgreement(payload) {
    const url = `/v1/manager/shared-agreement/override-employee-agreement`;
    return AxiosPerformance.post(url, payload);
  },

  $_mergeEditEmployeeAgreement(payload) {
    const url = `/v1/shared-agreement/merge-edit`;
    return AxiosPerformance.post(url, payload);
  },

  $_deleteAgreementKPI(id) {
    const url = `/v1/delete/employee-kpa/${id}`;
    return AxiosPerformance.delete(url);
  },

  $_approveAgreement(payload) {
    const url = `/v1/employee/performance-agreement/approve-agreement`;
    return AxiosPerformance.post(url, payload);
  },

  $_adminApproveAgreement(payload) {
    const url = `/v1/admin/performance-agreement/approve-agreement`;
    return AxiosPerformance.post(url, payload);
  },

  $_getOrgPerformanceAgreement() {
    const url = `/v1/performance-agreement/org/submitted-agreement/${OrgId}`;
    return AxiosPerformance.get(url);
  },

  $_getOrgAgreementEmployees(query,params) {
    const url = `/v1/performance-agreement/org/users/${OrgId}${query}${params}`;
    return AxiosPerformance.get(url);
  },

  $_getOrgAgreementUsers() {
    const url = `/v1/performance-agreement/org/users/${OrgId}`;
    return AxiosPerformance.get(url);
  },

  $_agreementReminder(payload) {
    const url = `/v1/admin/performance-agreement/send-reminder`;
    return AxiosPerformance.post(url, payload);
  },

  $_getAgreementComments(agreementId) {
    const url = `/v1/performance-agreement/agreement-comments/${agreementId}`;
    return AxiosPerformance.get(url);
  },

  $_postAgreementComments(payload) {
    const url = `/v1/performance-agreement/comment`;
    return AxiosPerformance.post(url, payload);
  },

  $_markAsRead(payload) {
    const url = `/v1/performance-agreement/read-comment`;
    return AxiosPerformance.patch(url, payload);
  },

  $_employeeAppraisal(employeeId) {
    const url = `/v1/performance-appraisal/employee/${employeeId}`;
    return AxiosPerformance.get(url);
  },

  $_calculateRatingScore(kpaId, ratingId, type) {
    const url = `/v1/performance-score/find-score/${kpaId}/${ratingId}?reviewType=${type}`;
    return AxiosPerformance.get(url);
  },

  $_calculateManagerRatingScore(kpaId, ratingId, type) {
    const url = `/v1/performance-score/get-score/${kpaId}/${ratingId}?reviewType=${type}`;
    return AxiosPerformance.get(url);
  },

  $_setEmployeeAppraisal(payload) {
    const url = `/v1/performance-appraisal/self-appraisal`;
    return AxiosPerformance.post(url, payload);
  },

  $_getEmployeeAppraisal(employeeId) {
    const url = `/v1/performance-appraisal/employee/${employeeId}`;
    return AxiosPerformance.get(url);
  },

  $_getScoreAppraisalFlag(score) {
    const url = `/v1/ranking/${score}/${OrgId}`;
    return AxiosPerformance.get(url);
  },

  $_getDirectReportAppraisals(employeeId) {
    const url = `/v1/performance-appraisal/direct-reports-appraisal/${employeeId}`;
    return AxiosPerformance.get(url);
  },

  $_getSingleEmployeeAppraisal(userId) {
    const url = `/v1/performance-appraisal/view-agreement/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_setManagerAppraisal(payload) {
    const url = `/v1/performance-appraisal/manager-appraisal`;
    return AxiosPerformance.post(url, payload);
  },

  $_approveAppraisal(payload) {
    const url = `/v1/performance-appraisal/approve-submit`;
    return AxiosPerformance.patch(url, payload);
  },

  $_returnAppraisal(payload) {
    const url = `/v1/performance-appraisal/revert`;
    return AxiosPerformance.post(url, payload);
  },

  $_getStatusReportEmployee(year, userId) {
    const url = `/v1/performance-report/employee-reports/${year}/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_postStatusReport(payload) {
    const url = `/v1/performance-report/add-report`;
    return AxiosPerformance.post(url, payload);
  },

  $_getAppraisalScoreCard(userId, params) {
    const url = `/v1/performance-appraisal/appraisal-scorecard/${userId}${params}`;
    return AxiosPerformance.get(url);
  },

  $_getOrganizationAppraisal(params) {
    const url = `/v1/performance-appraisal/organization-appraisal/${OrgId}${params}`;
    return AxiosPerformance.get(url);
  },

  $_getOrgSubmittedAppraisal(params,query) {
    const url = `/v1/performance-appraisal/org/submitted-appraisal/${OrgId}${params}${query}`;
    return AxiosPerformance.get(url);
  },

  $_orgCheckRatingScore(kpaId, ratingId, type) {
    const url = `/v1/performance-score/get-score/${kpaId}/${ratingId}?reviewType=${type}`;
    return AxiosPerformance.get(url);
  },

  $_appraiseOrganization(payload) {
    const url = `/v1/performance-appraisal/organization/performance/appraisal`;
    return AxiosPerformance.post(url, payload);
  },

  $_getEmployeePIP(userId) {
    const url = `/v1/performance-improvement/employee-improvement/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_getPIPSignatures(pipId) {
    const url = `/v1/performance-improvement/signatures/${pipId}`;
    return AxiosPerformance.get(url);
  },

  $_setEmployeePIP(payload) {
    const url = `/v1/performance-improvement/add-employee-pip`;
    return AxiosPerformance.post(url, payload);
  },

  $_getDirectReportsPIP(templateId, userId) {
    const url = `/v1/performance-improvement/direct-reports-pip/${templateId}/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_getDirectReportsReview(userId) {
    const url = `/v1/performance-improvement/direct-reports-review/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_getEmployeePreliminary(userId) {
    const url = `/v1/performance-improvement/employee-primary-detail/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_getReviewScores(actionPlanId, ratingId) {
    const url = `/v1/performance-improvement/get-pip-score/${actionPlanId}/${ratingId}`;
    return AxiosPerformance.get(url);
  },

  $_setReviewsPIP(payload) {
    const url = `/v1/performance-improvement/review-pip`;
    return AxiosPerformance.post(url, payload);
  },

  $_employeeCloseOut(userId) {
    const url = `/v1/performance-improvement/employee/close-out-session/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_getEmployeeReviews(employeeId) {
    const url = `/v1/performance-improvement/employee/pip-review/${employeeId}`;
    return AxiosPerformance.get(url);
  },

  $_getEmployeeNextStep(employeeId) {
    const url = `/v1/performance-improvement/review-next-step/employee/${employeeId}`;
    return AxiosPerformance.get(url);
  },

  $_setEmployeeNextStep(payload) {
    const url = `/v1/performance-improvement/review-next-step`;
    return AxiosPerformance.post(url, payload);
  },

  $_getEmployeeSummary(employeeId) {
    const url = `/v1/performance-improvement/summary/employee-outcome/${employeeId}`;
    return AxiosPerformance.get(url);
  },

  $_getPIPSignatories(templateId, query) {
    const url = `/v1/performance-improvement/pip-signatures/${templateId}${query}`;
    return AxiosPerformance.get(url);
  },

  $_setSummaryOutcome(payload) {
    const url = `v1/performance-improvement/pip-summary-outcome`;
    return AxiosPerformance.post(url, payload);
  },

  $_signPIPImprovement(payload) {
    const url = `/v1/performance-improvement/sign-pip`;
    return AxiosPerformance.post(url, payload);
  },

  $_signPIPCloseOut(payload) {
    const url = `/v1/performance-improvement/sign-pip-closeout-session`;
    return AxiosPerformance.post(url, payload);
  },

  $_signPIPReview(payload) {
    const url = `/v1/performance-improvement/sign-pip-review`;
    return AxiosPerformance.post(url, payload);
  },

  $_setCloseOut(payload) {
    const url = `/v1/performance-improvement/close-out-session`;
    return AxiosPerformance.post(url, payload);
  },

  $_getEmployeeCloseOutDetails(userId) {
    const url = `/v1/performance-improvement/direct-reports-closeout/${userId}`;
    return AxiosPerformance.get(url);
  },

  $_returnEmployeeAgreement(payload) {
    const url = `/v1/performance-agreement/return-agreement`;
    return AxiosPerformance.post(url, payload);
  },

  $_deleteAppraisalComments(commentId) {
    const url = `/v1/performance-appraisal/delete-comments/${commentId}`;
    return AxiosPerformance.delete(url);
  },

  $_deleteAgreementComments(commentId) {
    const url = `/v1/performance-agreement/delete-comments/${commentId}`;
    return AxiosPerformance.delete(url);
  },

  $_getManagerDirectReportStatus(id,year) {
    const url = `/v1/performance-agreement/direct-reports-agreement-status/${id}/${year}`;
    return AxiosPerformance.get(url);
  },
};
