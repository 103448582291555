import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "/advance-and-loan",
    name: "SalaryAdvance",
    component: () =>
      import("@/modules/Admin/BenefitsModule/AdvanceLoans/index"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/overtime",
    name: "Overtime",
    component: () => import("@/modules/Admin/BenefitsModule/Overtime/index"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/overtime/:id",
    name: "OvertimeDetails",
    component: () =>
      import("@/modules/Admin/BenefitsModule/Overtime/overtimeDetails"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/leave-management",
    name: "LeaveManagement",
    component: () => import("@/modules/Admin/BenefitsModule/Leave"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/leave-management/:id",
    name: "ViewLeaveDetails",
    component: () =>
      import("@/modules/Admin/BenefitsModule/Leave/leaveDetails"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/leave-management/leave-balance/:id",
    name: "ViewYearEndLeaveBalance",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/ViewYearEndLeaveBalance"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/leave-management/leave-balance/employee/:id",
    name: "LeaveHistory",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/LeaveHistory"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/leave-management/annual-leave-plan/:leaveYear",
    name: "ViewAdminAnnualLeavePlan",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/ViewAnnualLeavePlan"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/leave-management/annual-leave-plan/:year/:id",
    name: "ViewEmployeeAnnualLeavePlan",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/ViewEmployeeAnnualLeavePlan"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/leave-management/employee-annual-leave-plan/conflicts/:year/:startDate/:id/:conflictId",
    name: "EmployeeCalendarViewPlan",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/EmployeeCalendarViewPlan"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/advance/loans/:id",
    name: "ViewAdvanceDetails",
    component: () =>
      import("@/modules/Admin/BenefitsModule/AdvanceLoans/advanceLoanDetails"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/leave/consolidated",
    name: "ConsolidatedLeave",
    component: () =>
      import("@/modules/Admin/BenefitsModule/Leave/ConsolidatedLeave/index"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  }
];
